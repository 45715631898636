import * as ACTION_TYPES from './localityActionTypes';

export const localityReducerInitialState = {
	listLocality: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postLocality: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const localityReducer = (state = localityReducerInitialState, action) => {
	switch (action.type) {

		// List filtered action handlers
		case ACTION_TYPES.LOCALITY_REQUEST: {

			return {
				...state,
				listLocality: {
					...state.listLocality,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.LOCALITY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listLocality: {
					...state.listLocality,

					isFetching: false,
					hasError: false,
					error: localityReducerInitialState?.listLocality?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.LOCALITY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listLocality: {
					...state.listLocality,
					isFetching: false,
					hasError: true,
					data: localityReducerInitialState?.listLocality?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_LOCALITY_REQUEST: {
			return {
				...state,
				listLocality: localityReducerInitialState?.listLocality
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_LOCALITY_REQUEST: {

			return {
				...state,
				postLocality: {
					...state.postLocality,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_LOCALITY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postLocality: {
					...state.postLocality,

					isFetching: false,
					hasError: false,
					error: localityReducerInitialState?.postLocality?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_LOCALITY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postLocality: {
					...state.postLocality,
					isFetching: false,
					hasError: true,
					data: localityReducerInitialState?.postLocality?.data,
					error
				}
			};
		}
		// END POST

		// END action handlers
		default: {
			return state;
		}
	}
};

export default localityReducer;

export const getLocalityReducer = state => state.localityReducer;

// Getters list
export const getListLocality = state => getLocalityReducer(state)?.listLocality;
export const getListLocalityData = state => getListLocality(state)?.data;
export const getListLocalityMetaData = state => getListLocality(state)?.meta;
export const getListLocalityIsFetching = state => getListLocality(state)?.isFetching;
export const getListLocalityHasError = state => getListLocality(state)?.hasError;
export const getListLocalityError = state => getListLocality(state)?.error;


// Getters post
export const getPostLocality = state => getLocalityReducer(state)?.postLocality;
export const getPostLocalityData = state => getPostLocality(state)?.data;
export const getPostLocalityIsFetching = state => getPostLocality(state)?.isFetching;
export const getPostLocalityHasError = state => getPostLocality(state)?.hasError;
export const getPostLocalitysError = state => getPostLocality(state)?.error;
