export const DESPATCH_REQUEST = 'DESPATCH_REQUEST';
export const DESPATCH_REQUEST_SUCCESS = 'DESPATCH_REQUEST_SUCCESS';
export const DESPATCH_REQUEST_FAILURE = 'DESPATCH_REQUEST_FAILURE';
export const CLEAR_DESPATCH_REQUEST = 'CLEAR_DESPATCH_REQUEST'; 

//post
export const POST_DESPATCH_REQUEST = 'POST_DESPATCH_REQUEST';
export const POST_DESPATCH_REQUEST_SUCCESS = 'POST_DESPATCH_REQUEST_SUCCESS';
export const POST_DESPATCH_REQUEST_FAILURE = 'POST_DESPATCH_REQUEST_FAILURE';

//put
export const PUT_DESPATCH_REQUEST = 'PUT_DESPATCH_REQUEST';
export const PUT_DESPATCH_REQUEST_SUCCESS = 'PUT_DESPATCH_REQUEST_SUCCESS';
export const PUT_DESPATCH_REQUEST_FAILURE = 'PUT_DESPATCH_REQUEST_FAILURE';

//delete
export const DELETE_DESPATCH_REQUEST = 'DELETE_DESPATCH_REQUEST';
export const DELETE_DESPATCH_REQUEST_SUCCESS = 'DELETE_DESPATCH_REQUEST_SUCCESS';
export const DELETE_DESPATCH_REQUEST_FAILURE = 'DELETE_DESPATCH_REQUEST_FAILURE';

//add delegation
export const POST_DELEGATIONS_REQUEST = 'POST_DELEGATIONS_REQUEST';
export const POST_DELEGATIONS_REQUEST_SUCCESS = 'POST_DELEGATIONS_REQUEST_SUCCESS';
export const POST_DELEGATIONS_REQUEST_FAILURE = 'POST_DELEGATIONS_REQUEST_FAILURE';

//add delegation
export const GET_DELEGATIONS_REQUEST = 'GET_DELEGATIONS_REQUEST';
export const GET_DELEGATIONS_REQUEST_SUCCESS = 'GET_DELEGATIONS_REQUEST_SUCCESS';
export const GET_DELEGATIONS_REQUEST_FAILURE = 'GET_DELEGATIONS_REQUEST_FAILURE';
export const CLEAR_GET_DELEGATIONS_REQUEST_FAILURE = 'CLEAR_GET_DELEGATIONS_REQUEST_FAILURE'; 

//post
export const POST_ADD_COMMENTS_REQUEST = 'POST_ADD_COMMENTS_REQUEST';
export const POST_ADD_COMMENTS_REQUEST_SUCCESS = 'POST_ADD_COMMENTS_REQUEST_SUCCESS';
export const POST_ADD_COMMENTS_REQUEST_FAILURE = 'POST_ADD_COMMENTS_REQUEST_FAILURE';

//get comments
export const GET_COMMENTS_BY_ID_REQUEST = 'GET_COMMENTS_BY_ID_REQUEST';
export const GET_COMMENTS_BY_ID_SUCCESS = 'GET_COMMENTS_BY_ID_SUCCESS';
export const GET_COMMENTS_BY_ID_FAILURE = 'GET_COMMENTS_BY_ID_FAILURE';
export const CLEAR_GET_COMMENTS_BY_ID_REQUEST = 'CLEAR_GET_COMMENTS_BY_ID_REQUEST'; 


//POST close incident
export const POST_INCIDENT_CLOSE_REQUEST = 'POST_INCIDENT_CLOSE_REQUEST';
export const POST_INCIDENT_CLOSE_REQUEST_SUCCESS = 'POST_INCIDENT_CLOSE_REQUEST_SUCCESS';
export const POST_INCIDENT_CLOSE_REQUEST_FAILURE = 'POST_INCIDENT_CLOSE_REQUEST_FAILURE';
