import * as ACTION_TYPES from './colorCodeActionTypes';

export const colorCodeReducerInitialState = {
	listColorCode: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postColorCode: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const colorCodeReducer = (state = colorCodeReducerInitialState, action) => {
	switch (action.type) {

		// List action handlers
		case ACTION_TYPES.COLOR_CODE_REQUEST: {

			return {
				...state,
				listColorCode: {
					...state.listColorCode,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.COLOR_CODE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listColorCode: {
					...state.listColorCode,

					isFetching: false,
					hasError: false,
					error: colorCodeReducerInitialState?.listColorCode?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.COLOR_CODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listColorCode: {
					...state.listColorCode,
					isFetching: false,
					hasError: true,
					data: colorCodeReducerInitialState?.listColorCode?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_COLOR_CODE_REQUEST: {
			return {
				...state,
				listColorCode: colorCodeReducerInitialState?.listColorCode
			};
		}
		// END  action handlers

		// post
		case ACTION_TYPES.POST_COLOR_CODE_REQUEST: {

			return {
				...state,
				postColorCode: {
					...state.postColorCode,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_COLOR_CODE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postColorCode: {
					...state.postColorCode,

					isFetching: false,
					hasError: false,
					error: colorCodeReducerInitialState?.postColorCode?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_COLOR_CODE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postColorCode: {
					...state.postColorCode,
					isFetching: false,
					hasError: true,
					data: colorCodeReducerInitialState?.postColorCode?.data,
					error
				}
			};
		}
		// END POST

		// END Find action handlers
		default: {
			return state;
		}
	}
};

export default colorCodeReducer;

export const getColorCodeReducer = state => state.colorCodeReducer;

// Getters list
export const getListColorCode = state => getColorCodeReducer(state)?.listColorCode;
export const getListColorCodeData = state => getListColorCode(state)?.data;
export const getListColorCodeMetaData = state => getListColorCode(state)?.meta;
export const getListColorCodeIsFetching = state => getListColorCode(state)?.isFetching;
export const getListColorCodeHasError = state => getListColorCode(state)?.hasError;
export const getListColorCodeError = state => getListColorCode(state)?.error;


// Getters post
export const getPostColorCode = state => getColorCodeReducer(state)?.postColorCode;
export const getPostColorCodeData = state => getPostColorCode(state)?.data;
export const getPostColorCodeIsFetching = state => getPostColorCode(state)?.isFetching;
export const getPostColorCodeHasError = state => getPostColorCode(state)?.hasError;
export const getPostColorCodesError = state => getPostColorCode(state)?.error;
