import * as ACTIONS from './executingEntityActionTypes';

export const executingEntityReducerInitialState = {
	listExecutingEntity: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postExecutingEntityType: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const executingEntityReducer = (state = executingEntityReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTIONS.EXECUTING_ENTITY_REQUEST: {

			return {
				...state,
				listExecutingEntity: {
					...state.listExecutingEntity,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTIONS.EXECUTING_ENTITY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listExecutingEntity: {
					...state.listExecutingEntity,

					isFetching: false,
					hasError: false,
					error: executingEntityReducerInitialState?.listExecutingEntity?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTIONS.EXECUTING_ENTITY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listExecutingEntity: {
					...state.listExecutingEntity,
					isFetching: false,
					hasError: true,
					data: executingEntityReducerInitialState?.listExecutingEntity?.data,
					error
				}
			};
		}
		case ACTIONS.CLEAR_EXECUTING_ENTITY_REQUEST: {
			return {
				...state,
				listExecutingEntity: executingEntityReducerInitialState?.listExecutingEntity
			};
		}
		// END executing entity type filtered action handlers

		// post executing entity type
		case ACTIONS.POST_EXECUTING_ENTITY_REQUEST: {

			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTIONS.POST_EXECUTING_ENTITY_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,

					isFetching: false,
					hasError: false,
					error: executingEntityReducerInitialState?.postExecutingEntityType?.error,
					data: data?.data
				}
			};
		}
		case ACTIONS.POST_EXECUTING_ENTITY_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,
					isFetching: false,
					hasError: true,
					data: executingEntityReducerInitialState?.postExecutingEntityType?.data,
					error
				}
			};
		}
		// END POST

		// END Find plants filtered action handlers
		default: {
			return state;
		}
	}
};

export default executingEntityReducer;

export const getExecutingEntityReducer = state => state.executingEntityReducer;

// Getters list executing entity type
export const getListExecutingEntity = state => getExecutingEntityReducer(state)?.listExecutingEntity;
export const getListExecutingEntityData = state => getListExecutingEntity(state)?.data;
export const getListExecutingEntityMetaData = state => getListExecutingEntity(state)?.meta;
export const getListExecutingEntityIsFetching = state => getListExecutingEntity(state)?.isFetching;
export const getListExecutingEntityHasError = state => getListExecutingEntity(state)?.hasError;
export const getListExecutingEntityError = state => getListExecutingEntity(state)?.error;


// Getters post executing entity type
export const getPostExecutingEntityType = state => getExecutingEntityReducer(state)?.postExecutingEntityType;
export const getPostExecutingEntityTypeData = state => getPostExecutingEntityType(state)?.data;
export const getPostExecutingEntityTypeIsFetching = state => getPostExecutingEntityType(state)?.isFetching;
export const getPostExecutingEntityTypeHasError = state => getPostExecutingEntityType(state)?.hasError;
export const getPostExecutingEntityTypesError = state => getPostExecutingEntityType(state)?.error;
