export const CALL_REQUEST = 'CALL_REQUEST';
export const CALL_REQUEST_SUCCESS = 'CALL_REQUEST_SUCCESS';
export const CALL_REQUEST_FAILURE = 'CALL_REQUEST_FAILURE';
export const CLEAR_CALL_REQUEST = 'CLEAR_CALL_REQUEST'; 

//post
export const POST_CALL_REQUEST = 'POST_CALL_REQUEST';
export const POST_CALL_REQUEST_SUCCESS = 'POST_CALL_REQUEST_SUCCESS';
export const POST_CALL_REQUEST_FAILURE = 'POST_CALL_REQUEST_FAILURE';

//put
export const PUT_CALL_REQUEST = 'PUT_CALL_REQUEST';
export const PUT_CALL_REQUEST_SUCCESS = 'PUT_CALL_REQUEST_SUCCESS';
export const PUT_CALL_REQUEST_FAILURE = 'PUT_CALL_REQUEST_FAILURE';

//delete
export const DELETE_CALL_REQUEST = 'DELETE_CALL_REQUEST';
export const DELETE_CALL_REQUEST_SUCCESS = 'DELETE_CALL_REQUEST_SUCCESS';
export const DELETE_CALL_REQUEST_FAILURE = 'DELETE_CALL_REQUEST_FAILURE';