export const EXECUTING_ENTITY_REQUEST = 'EXECUTING_ENTITY_REQUEST';
export const EXECUTING_ENTITY_REQUEST_SUCCESS = 'EXECUTING_ENTITY_REQUEST_SUCCESS';
export const EXECUTING_ENTITY_REQUEST_FAILURE = 'EXECUTING_ENTITY_REQUEST_FAILURE';
export const CLEAR_EXECUTING_ENTITY_REQUEST = 'CLEAR_EXECUTING_ENTITY_REQUEST'; 

//post
export const POST_EXECUTING_ENTITY_REQUEST = 'POST_EXECUTING_ENTITY_REQUEST';
export const POST_EXECUTING_ENTITY_REQUEST_SUCCESS = 'POST_EXECUTING_ENTITY_REQUEST_SUCCESS';
export const POST_EXECUTING_ENTITY_REQUEST_FAILURE = 'POST_EXECUTING_ENTITY_REQUEST_FAILURE';

//put
export const PUT_EXECUTING_ENTITY_REQUEST = 'PUT_EXECUTING_ENTITY_REQUEST';
export const PUT_EXECUTING_ENTITY_REQUEST_SUCCESS = 'PUT_EXECUTING_ENTITY_REQUEST_SUCCESS';
export const PUT_EXECUTING_ENTITY_REQUEST_FAILURE = 'PUT_EXECUTING_ENTITY_REQUEST_FAILURE';

//delete
export const DELETE_EXECUTING_ENTITY_REQUEST = 'DELETE_EXECUTING_ENTITY_REQUEST';
export const DELETE_EXECUTING_ENTITY_REQUEST_SUCCESS = 'DELETE_EXECUTING_ENTITY_REQUEST_SUCCESS';
export const DELETE_EXECUTING_ENTITY_REQUEST_FAILURE = 'DELETE_EXECUTING_ENTITY_REQUEST_FAILURE';
