export const COLOR_CODE_REQUEST = 'COLOR_CODE_REQUEST';
export const COLOR_CODE_REQUEST_SUCCESS = 'COLOR_CODE_REQUEST_SUCCESS';
export const COLOR_CODE_REQUEST_FAILURE = 'COLOR_CODE_REQUEST_FAILURE';
export const CLEAR_COLOR_CODE_REQUEST = 'CLEAR_COLOR_CODE_REQUEST'; 

//post
export const POST_COLOR_CODE_REQUEST = 'POST_COLOR_CODE_REQUEST';
export const POST_COLOR_CODE_REQUEST_SUCCESS = 'POST_COLOR_CODE_REQUEST_SUCCESS';
export const POST_COLOR_CODE_REQUEST_FAILURE = 'POST_COLOR_CODE_REQUEST_FAILURE';

//put
export const PUT_COLOR_CODE_REQUEST = 'PUT_COLOR_CODE_REQUEST';
export const PUT_COLOR_CODE_REQUEST_SUCCESS = 'PUT_COLOR_CODE_REQUEST_SUCCESS';
export const PUT_COLOR_CODE_REQUEST_FAILURE = 'PUT_COLOR_CODE_REQUEST_FAILURE';

//delete
export const DELETE_COLOR_CODE_REQUEST = 'DELETE_COLOR_CODE_REQUEST';
export const DELETE_COLOR_CODE_REQUEST_SUCCESS = 'DELETE_COLOR_CODE_REQUEST_SUCCESS';
export const DELETE_COLOR_CODE_REQUEST_FAILURE = 'DELETE_COLOR_CODE_REQUEST_FAILURE';