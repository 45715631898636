import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
/* import { reducer as notifications } from 'react-notification-system-redux' */


import { loginReducer, loginReducerInitialState } from 'src/redux/login/loginReducer';
import { globalDataReducer, globalDataReducerInitialState } from 'src/redux/globalData/globalDataReducer';
import { usersReducer,usersReducerInitialState } from "src/redux/users/usersReducer";
import { coreApiReducer,coreApiReducerInitialState } from "src/redux/coreApi/coreApiReducer";
import { executingEntityTypeReducer,executingEntityTypeReducerInitialState } from "src/redux/executingEntityType/executingEntityTypeReducer";
import { executingEntityReducer,executingEntityReducerInitialState } from "src/redux/executingEntity/executingEntityReducer";
import { typificationReducer,typificationReducerInitialState } from "src/redux/typification/typificationReducer";
import { cancellationReasonReducer,cancellationReasonReducerInitialState } from "src/redux/cancellationReason/cancellationReasonReducer";
import { despatchReducer,despatchReducerInitialState } from "src/redux/despatch/despatchReducer";
import { callReducer,callReducerInitialState } from "src/redux/call/callReducer";
import { colorCodeReducer,colorCodeReducerInitialState } from "src/redux/colorCode/colorCodeReducer";
import { localityReducer,localityReducerInitialState } from "src/redux/locality/localityReducer";
import { incidentReducer,incidentReducerInitialState } from "src/redux/incident/incidentReducer";
import { reportReducer,reportReducerInitialState } from "src/redux/report/reportReducer";
import { rolesReducer,rolesReducerInitialState } from "src/redux/roles/rolesReducer";
import { groupReducer,groupReducerInitialState } from "src/redux/group/groupReducer";

import { LOGOUT } from 'src/redux/login/loginActionTypes';


const createRootReducer = history => {
	const appReducer = combineReducers({
		router: connectRouter(history),
/* 		notifications, */
		loginReducer,
		globalDataReducer,
		usersReducer,
		coreApiReducer,
		executingEntityTypeReducer,
		executingEntityReducer,
		typificationReducer,
		cancellationReasonReducer,
		despatchReducer,
		callReducer,
		colorCodeReducer,
		localityReducer,
		incidentReducer,
		reportReducer,
		rolesReducer,
		groupReducer,
	});

	return (state, action) => {
		if ( action.type === LOGOUT ) {
			state.globalDataReducer = globalDataReducerInitialState;
			state.usersReducer = usersReducerInitialState
			state.coreApiReducer = coreApiReducerInitialState
			state.executingEntityTypeReducer = executingEntityTypeReducerInitialState;
			state.loginReducer = loginReducerInitialState
			state.executingEntityReducer = executingEntityReducerInitialState
			state.typificationReducer = typificationReducerInitialState;
			state.cancellationReasonReducer = cancellationReasonReducerInitialState;
			state.despatchReducer = despatchReducerInitialState;
			state.callReducer = callReducerInitialState;
			state.colorCodeReducer = colorCodeReducerInitialState;
			state.localityReducer = localityReducerInitialState;
			state.incidentReducer = incidentReducerInitialState;
			state.reportReducer = reportReducerInitialState;
			state.rolesReducer = rolesReducerInitialState;
			state.groupReducer = groupReducerInitialState;

		}
		return appReducer(state, action);
	}
};

export default createRootReducer;
