import * as ACTION_TYPES from './despatchActionTypes';

export const despatchReducerInitialState = {
	listDespatch: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postDespatch: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listDelegations: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listCommentsById: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postCloseIncident: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
};

export const despatchReducer = (state = despatchReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.DESPATCH_REQUEST: {

			return {
				...state,
				listDespatch: {
					...state.listDespatch,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.DESPATCH_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listDespatch: {
					...state.listDespatch,

					isFetching: false,
					hasError: false,
					error: despatchReducerInitialState?.listDespatch?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.DESPATCH_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listDespatch: {
					...state.listDespatch,
					isFetching: false,
					hasError: true,
					data: despatchReducerInitialState?.listDespatch?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_DESPATCH_REQUEST: {
			return {
				...state,
				listDespatch: despatchReducerInitialState?.listDespatch
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_DESPATCH_REQUEST: {

			return {
				...state,
				postDespatch: {
					...state.postDespatch,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_DESPATCH_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postDespatch: {
					...state.postDespatch,

					isFetching: false,
					hasError: false,
					error: despatchReducerInitialState?.postDespatch?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_DESPATCH_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postDespatch: {
					...state.postDespatch,
					isFetching: false,
					hasError: true,
					data: despatchReducerInitialState?.postDespatch?.data,
					error
				}
			};
		}
		// END POST

		// List delegations
		case ACTION_TYPES.GET_DELEGATIONS_REQUEST: {

			return {
				...state,
				listDelegations: {
					...state.listDelegations,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_DELEGATIONS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listDelegations: {
					...state.listDelegations,

					isFetching: false,
					hasError: false,
					error: despatchReducerInitialState?.listDelegations?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.GET_DELEGATIONS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listDelegations: {
					...state.listDelegations,
					isFetching: false,
					hasError: true,
					data: despatchReducerInitialState?.listDelegations?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_DELEGATIONS_REQUEST_FAILURE: {
			return {
				...state,
				listDelegations: despatchReducerInitialState?.listDelegations
			};
		}
		// END filtered action handlers

		// List comments by id
		case ACTION_TYPES.GET_COMMENTS_BY_ID_REQUEST: {

			return {
				...state,
				listCommentsById: {
					...state.listCommentsById,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GET_COMMENTS_BY_ID_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listCommentsById: {
					...state.listCommentsById,

					isFetching: false,
					hasError: false,
					error: despatchReducerInitialState?.listCommentsById?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.GET_COMMENTS_BY_ID_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listCommentsById: {
					...state.listCommentsById,
					isFetching: false,
					hasError: true,
					data: despatchReducerInitialState?.listCommentsById?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GET_COMMENTS_BY_ID_REQUEST: {
			return {
				...state,
				listCommentsById: despatchReducerInitialState?.listCommentsById
			};
		}
		// END filtered action handlers
		//POST close incident
		case ACTION_TYPES.POST_INCIDENT_CLOSE_REQUEST: {

			return {
				...state,
				postCloseIncident: {
					...state.postCloseIncident,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_INCIDENT_CLOSE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postCloseIncident: {
					...state.postCloseIncident,

					isFetching: false,
					hasError: false,
					error: despatchReducerInitialState?.postCloseIncident?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.POST_INCIDENT_CLOSE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postCloseIncident: {
					...state.postCloseIncident,
					isFetching: false,
					hasError: true,
					data: despatchReducerInitialState?.postCloseIncident?.data,
					error
				}
			};
		}
		// END  close incident
		default: {
			return state;
		}
	}
};

export default despatchReducer;

export const getDespatchReducer = state => state.despatchReducer;

// Getters list
export const getListDespatch = state => getDespatchReducer(state)?.listDespatch;
export const getListDespatchData = state => getListDespatch(state)?.data;
export const getListDespatchMetaData = state => getListDespatch(state)?.meta;
export const getListDespatchIsFetching = state => getListDespatch(state)?.isFetching;
export const getListDespatchHasError = state => getListDespatch(state)?.hasError;
export const getListDespatchError = state => getListDespatch(state)?.error;


// Getters post
export const getPostDespatch = state => getDespatchReducer(state)?.postDespatch;
export const getPostDespatchData = state => getPostDespatch(state)?.data;
export const getPostDespatchIsFetching = state => getPostDespatch(state)?.isFetching;
export const getPostDespatchHasError = state => getPostDespatch(state)?.hasError;
export const getPostDespatchsError = state => getPostDespatch(state)?.error;


//delegations list
// Getters list
export const getListDelegations = state => getDespatchReducer(state)?.listDelegations;
export const getListDelegationshData = state => getListDelegations(state)?.data;
export const getListDelegationsMetaData = state => getListDelegations(state)?.meta;
export const getListDelegationshIsFetching = state => getListDelegations(state)?.isFetching;
export const getListDelegationshHasError = state => getListDelegations(state)?.hasError;
export const getListDelegationsError = state => getListDelegations(state)?.error;


// Getters list comments by id
export const getListCommentsById = state => getDespatchReducer(state)?.listCommentsById;
export const getListCommentsByIdData = state => getListCommentsById(state)?.data;
export const getListCommentsByIdMetaData = state => getListCommentsById(state)?.meta;
export const getListCommentsByIdIsFetching = state => getListCommentsById(state)?.isFetching;
export const getListCommentsByIdHasError = state => getListCommentsById(state)?.hasError;
export const getListCommentsByIdError = state => getListCommentsById(state)?.error;
