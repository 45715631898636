export const GROUP_REQUEST = 'GROUP_REQUEST';
export const GROUP_REQUEST_SUCCESS = 'GROUP_REQUEST_SUCCESS';
export const GROUP_REQUEST_FAILURE = 'GROUP_REQUEST_FAILURE';
export const CLEAR_GROUP_REQUEST = 'CLEAR_GROUP_REQUEST'; 

//post
export const POST_GROUP_REQUEST = 'POST_GROUP_REQUEST';
export const POST_GROUP_REQUEST_SUCCESS = 'POST_GROUP_REQUEST_SUCCESS';
export const POST_GROUP_REQUEST_FAILURE = 'POST_GROUP_REQUEST_FAILURE';

//put
export const PUT_GROUP_REQUEST = 'PUT_GROUP_REQUEST';
export const PUT_GROUP_REQUEST_SUCCESS = 'PUT_GROUP_REQUEST_SUCCESS';
export const PUT_GROUP_REQUEST_FAILURE = 'PUT_GROUP_REQUEST_FAILURE';

//delete
export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_REQUEST_SUCCESS = 'DELETE_GROUP_REQUEST_SUCCESS';
export const DELETE_GROUP_REQUEST_FAILURE = 'DELETE_GROUP_REQUEST_FAILURE';