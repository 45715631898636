import * as ACTION_TYPES from './incidentActionTypes';

export const incidentReducerInitialState = {
	listIncident: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postIncident: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	listIncidentToIdCall: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	showIncident: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const incidentReducer = (state = incidentReducerInitialState, action) => {
	switch (action.type) {

		// List filtered action handlers
		case ACTION_TYPES.INCIDENT_REQUEST: {

			return {
				...state,
				listIncident: {
					...state.listIncident,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.INCIDENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listIncident: {
					...state.listIncident,

					isFetching: false,
					hasError: false,
					error: incidentReducerInitialState?.listIncident?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.INCIDENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listIncident: {
					...state.listIncident,
					isFetching: false,
					hasError: true,
					data: incidentReducerInitialState?.listIncident?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_INCIDENT_REQUEST: {
			return {
				...state,
				listIncident: incidentReducerInitialState?.listIncident
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_INCIDENT_REQUEST: {

			return {
				...state,
				postIncident: {
					...state.postIncident,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_INCIDENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postIncident: {
					...state.postIncident,

					isFetching: false,
					hasError: false,
					error: incidentReducerInitialState?.postIncident?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_INCIDENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postIncident: {
					...state.postIncident,
					isFetching: false,
					hasError: true,
					data: incidentReducerInitialState?.postIncident?.data,
					error
				}
			};
		}
		// END POST

		// List filtered action handlers
		case ACTION_TYPES.INCIDENT_TO_ID_CALL_REQUEST: {

			return {
				...state,
				listIncidentToIdCall: {
					...state.listIncidentToIdCall,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.INCIDENT_TO_ID_CALL_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listIncidentToIdCall: {
					...state.listIncidentToIdCall,

					isFetching: false,
					hasError: false,
					error: incidentReducerInitialState?.listIncidentToIdCall?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.INCIDENT_TO_ID_CALL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listIncidentToIdCall: {
					...state.listIncidentToIdCall,
					isFetching: false,
					hasError: true,
					data: incidentReducerInitialState?.listIncidentToIdCall?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_INCIDENT_TO_ID_CALL_REQUEST: {
			return {
				...state,
				listIncidentToIdCall: incidentReducerInitialState?.listIncidentToIdCall
			};
		}
		// END filtered action handlers

		// Show filtered action handlers
		case ACTION_TYPES.SHOW_INCIDENT_REQUEST: {

			return {
				...state,
				showIncident: {
					...state.showIncident,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.SHOW_INCIDENT_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				showIncident: {
					...state.showIncident,

					isFetching: false,
					hasError: false,
					error: incidentReducerInitialState?.showIncident?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.SHOW_INCIDENT_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				showIncident: {
					...state.showIncident,
					isFetching: false,
					hasError: true,
					data: incidentReducerInitialState?.showIncident?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_SHOW_INCIDENT_REQUEST: {
			return {
				...state,
				showIncident: incidentReducerInitialState?.showIncident
			};
		}
		// END filtered action handlers

		// END action handlers
		default: {
			return state;
		}
	}
};

export default incidentReducer;

export const getIncidentReducer = state => state.incidentReducer;

// Getters list
export const getListIncident = state => getIncidentReducer(state)?.listIncident;
export const getListIncidentData = state => getListIncident(state)?.data;
export const getListIncidentMetaData = state => getListIncident(state)?.meta;
export const getListIncidentIsFetching = state => getListIncident(state)?.isFetching;
export const getListIncidentHasError = state => getListIncident(state)?.hasError;
export const getListIncidentError = state => getListIncident(state)?.error;

// Getters post
export const getPostIncident = state => getIncidentReducer(state)?.postIncident;
export const getPostIncidentData = state => getPostIncident(state)?.data;
export const getPostIncidentIsFetching = state => getPostIncident(state)?.isFetching;
export const getPostIncidentHasError = state => getPostIncident(state)?.hasError;
export const getPostIncidentsError = state => getPostIncident(state)?.error;

// Getters ListIncidentToIdCall
export const getListIncidentToIdCall = state => getIncidentReducer(state)?.listIncidentToIdCall;
export const getListIncidentToIdCallData = state => getListIncidentToIdCall(state)?.data;
export const getListIncidentToIdCallMetaData = state => getListIncidentToIdCall(state)?.meta;
export const getListIncidentToIdCallIsFetching = state => getListIncidentToIdCall(state)?.isFetching;
export const getListIncidentToIdCallHasError = state => getListIncidentToIdCall(state)?.hasError;
export const getListIncidentToIdCallsError = state => getListIncidentToIdCall(state)?.error;

// Getters ShowIncident
export const getShowIncident = state => getIncidentReducer(state)?.showIncident;
export const getShowIncidentData = state => getShowIncident(state)?.data;
export const getShowIncidentMetaData = state => getShowIncident(state)?.meta;
export const getShowIncidentIsFetching = state => getShowIncident(state)?.isFetching;
export const getShowIncidentHasError = state => getShowIncident(state)?.hasError;
export const getShowIncidentsError = state => getListIncidentToIdCall(state)?.error;