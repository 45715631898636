import * as ACTION_TYPES from './reportActionTypes';

export const reportReducerInitialState = {
	reportTotalityOfIncidents: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportTotalityOfIncidentsNew: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportTotalityOfIncidentsInProcess: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	reportTotalityOfCallsReceivedByOperators: {
		data: undefined,
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	}
};

export const reportReducer = (state = reportReducerInitialState, action) => {
	switch (action.type) {

		// Get report
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_REQUEST: {

			return {
				...state,
				reportTotalityOfIncidents: {
					...state.reportTotalityOfIncidents,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				reportTotalityOfIncidents: {
					...state.reportTotalityOfIncidents,

					isFetching: false,
					hasError: false,
					error: reportReducerInitialState?.reportTotalityOfIncidents?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportTotalityOfIncidents: {
					...state.reportTotalityOfIncidents,
					isFetching: false,
					hasError: true,
					data: reportReducerInitialState?.reportTotalityOfIncidents?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_TOTALITY_OF_INCIDENTS_REQUEST: {
			return {
				...state,
				reportTotalityOfIncidents: reportReducerInitialState?.reportTotalityOfIncidents
			};
		}
		// END filtered action handlers

		// Get report
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST: {

			return {
				...state,
				reportTotalityOfIncidentsNew: {
					...state.reportTotalityOfIncidentsNew,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				reportTotalityOfIncidentsNew: {
					...state.reportTotalityOfIncidentsNew,

					isFetching: false,
					hasError: false,
					error: reportReducerInitialState?.reportTotalityOfIncidentsNew?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportTotalityOfIncidentsNew: {
					...state.reportTotalityOfIncidentsNew,
					isFetching: false,
					hasError: true,
					data: reportReducerInitialState?.reportTotalityOfIncidentsNew?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST: {
			return {
				...state,
				reportTotalityOfIncidentsNew: reportReducerInitialState?.reportTotalityOfIncidentsNew
			};
		}
		// END filtered action handlers

		// Get report
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST: {

			return {
				...state,
				reportTotalityOfIncidentsInProcess: {
					...state.reportTotalityOfIncidentsInProcess,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				reportTotalityOfIncidentsInProcess: {
					...state.reportTotalityOfIncidentsInProcess,

					isFetching: false,
					hasError: false,
					error: reportReducerInitialState?.reportTotalityOfIncidentsInProcess?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportTotalityOfIncidentsInProcess: {
					...state.reportTotalityOfIncidentsInProcess,
					isFetching: false,
					hasError: true,
					data: reportReducerInitialState?.reportTotalityOfIncidentsInProcess?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST: {
			return {
				...state,
				reportTotalityOfIncidentsInProcess: reportReducerInitialState?.reportTotalityOfIncidentsInProcess
			};
		}
		// END filtered action handlers

		// Get Report Totality Of Calls Received By Operators  report
		case ACTION_TYPES.REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST: {

			return {
				...state,
				reportTotalityOfCallsReceivedByOperators: {
					...state.reportTotalityOfCallsReceivedByOperators,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				reportTotalityOfCallsReceivedByOperators: {
					...state.reportTotalityOfCallsReceivedByOperators,

					isFetching: false,
					hasError: false,
					error: reportReducerInitialState?.reportTotalityOfCallsReceivedByOperators?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				reportTotalityOfCallsReceivedByOperators: {
					...state.reportTotalityOfCallsReceivedByOperators,
					isFetching: false,
					hasError: true,
					data: reportReducerInitialState?.reportTotalityOfCallsReceivedByOperators?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST: {
			return {
				...state,
				reportTotalityOfCallsReceivedByOperators: reportReducerInitialState?.reportTotalityOfCallsReceivedByOperators
			};
		}
		// END Report Totality Of Calls Received By Operators  filtered action handlers

		

		// END action handlers
		default: {
			return state;
		}
	}
};

export default reportReducer;

export const getReportReducer = state => state.reportReducer;

// Getters list
export const getReportTotalityOfIncidents = state => getReportReducer(state)?.reportTotalityOfIncidents;
export const getReportTotalityOfIncidentsData = state => getReportTotalityOfIncidents(state)?.data;
export const getReportTotalityOfIncidentsIsFetching = state => getReportTotalityOfIncidents(state)?.isFetching;
export const getReportTotalityOfIncidentsHasError = state => getReportTotalityOfIncidents(state)?.hasError;
export const getReportTotalityOfIncidentsError = state => getReportTotalityOfIncidents(state)?.error;

// Getters list Incident New
export const getReportTotalityOfIncidentsNew = state => getReportReducer(state)?.reportTotalityOfIncidentsNew;
export const getReportTotalityOfIncidentsNewData = state => getReportTotalityOfIncidentsNew(state)?.data;
export const getReportTotalityOfIncidentsNewIsFetching = state => getReportTotalityOfIncidentsNew(state)?.isFetching;
export const getReportTotalityOfIncidentsNewHasError = state => getReportTotalityOfIncidentsNew(state)?.hasError;
export const getReportTotalityOfIncidentsNewError = state => getReportTotalityOfIncidentsNew(state)?.error;

// Getters list In Process
export const getReportTotalityOfIncidentsInProcess = state => getReportReducer(state)?.reportTotalityOfIncidentsInProcess;
export const getReportTotalityOfIncidentsInProcessData = state => getReportTotalityOfIncidentsInProcess(state)?.data;
export const getReportTotalityOfIncidentsInProcessIsFetching = state => getReportTotalityOfIncidentsInProcess(state)?.isFetching;
export const getReportTotalityOfIncidentsInProcessHasError = state => getReportTotalityOfIncidentsInProcess(state)?.hasError;
export const getReportTotalityOfIncidentsInProcessError = state => getReportTotalityOfIncidentsInProcess(state)?.error;

// Getters Report Totality Of Calls Received By Operators 
export const getReportTotalityOfCallsReceivedByOperators = state => getReportReducer(state)?.reportTotalityOfCallsReceivedByOperators;
export const getReportTotalityOfCallsReceivedByOperatorsData = state => getReportTotalityOfCallsReceivedByOperators(state)?.data;
export const getReportTotalityOfCallsReceivedByOperatorsIsFetching = state => getReportTotalityOfCallsReceivedByOperators(state)?.isFetching;
export const getReportTotalityOfCallsReceivedByOperatorsHasError = state => getReportTotalityOfCallsReceivedByOperators(state)?.hasError;
export const getReportTotalityOfCallsReceivedByOperatorsError = state => getReportTotalityOfCallsReceivedByOperators(state)?.error;