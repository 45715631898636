export const INCIDENT_REQUEST = 'INCIDENT_REQUEST';
export const INCIDENT_REQUEST_SUCCESS = 'INCIDENT_REQUEST_SUCCESS';
export const INCIDENT_REQUEST_FAILURE = 'INCIDENT_REQUEST_FAILURE';
export const CLEAR_INCIDENT_REQUEST = 'CLEAR_INCIDENT_REQUEST'; 

//post
export const POST_INCIDENT_REQUEST = 'POST_INCIDENT_REQUEST';
export const POST_INCIDENT_REQUEST_SUCCESS = 'POST_INCIDENT_REQUEST_SUCCESS';
export const POST_INCIDENT_REQUEST_FAILURE = 'POST_INCIDENT_REQUEST_FAILURE';

//put
export const PUT_INCIDENT_REQUEST = 'PUT_INCIDENT_REQUEST';
export const PUT_INCIDENT_REQUEST_SUCCESS = 'PUT_INCIDENT_REQUEST_SUCCESS';
export const PUT_INCIDENT_REQUEST_FAILURE = 'PUT_INCIDENT_REQUEST_FAILURE';

//delete
export const DELETE_INCIDENT_REQUEST = 'DELETE_INCIDENT_REQUEST';
export const DELETE_INCIDENT_REQUEST_SUCCESS = 'DELETE_INCIDENT_REQUEST_SUCCESS';
export const DELETE_INCIDENT_REQUEST_FAILURE = 'DELETE_INCIDENT_REQUEST_FAILURE';

//IncidentToIdCall
export const INCIDENT_TO_ID_CALL_REQUEST = 'INCIDENT_TO_ID_CALL_REQUEST';
export const INCIDENT_TO_ID_CALL_REQUEST_SUCCESS = 'INCIDENT_TO_ID_CALL_REQUEST_SUCCESS';
export const INCIDENT_TO_ID_CALL_REQUEST_FAILURE = 'INCIDENT_TO_ID_CALL_REQUEST_FAILURE';
export const CLEAR_INCIDENT_TO_ID_CALL_REQUEST = 'CLEAR_INCIDENT_TO_ID_CALL_REQUEST'; 

//ShowIncidentForId
export const SHOW_INCIDENT_REQUEST = 'SHOW_INCIDENT_REQUEST';
export const SHOW_INCIDENT_REQUEST_SUCCESS = 'SHOW_INCIDENT_REQUEST_SUCCESS';
export const SHOW_INCIDENT_REQUEST_FAILURE = 'SHOW_INCIDENT_REQUEST_FAILURE';
export const CLEAR_SHOW_INCIDENT_REQUEST = 'CLEAR_SHOW_INCIDENT_REQUEST'; 