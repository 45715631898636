import * as ACTION_TYPES from './executingEntityTypeActionTypes';

export const executingEntityTypeReducerInitialState = {
	listExecutingEntityType: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postExecutingEntityType: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const executingEntityTypeReducer = (state = executingEntityTypeReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.EXECUTING_ENTITY_TYPE_REQUEST: {

			return {
				...state,
				listExecutingEntityType: {
					...state.listExecutingEntityType,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.EXECUTING_ENTITY_TYPE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listExecutingEntityType: {
					...state.listExecutingEntityType,

					isFetching: false,
					hasError: false,
					error: executingEntityTypeReducerInitialState?.listExecutingEntityType?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.EXECUTING_ENTITY_TYPE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listExecutingEntityType: {
					...state.listExecutingEntityType,
					isFetching: false,
					hasError: true,
					data: executingEntityTypeReducerInitialState?.listExecutingEntityType?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_EXECUTING_ENTITY_TYPE_REQUEST: {
			return {
				...state,
				listExecutingEntityType: executingEntityTypeReducerInitialState?.listExecutingEntityType
			};
		}
		// END executing entity type filtered action handlers

		// post executing entity type
		case ACTION_TYPES.POST_EXECUTING_ENTITY_TYPE_REQUEST: {

			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_EXECUTING_ENTITY_TYPE_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,

					isFetching: false,
					hasError: false,
					error: executingEntityTypeReducerInitialState?.postExecutingEntityType?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_EXECUTING_ENTITY_TYPE_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postExecutingEntityType: {
					...state.postExecutingEntityType,
					isFetching: false,
					hasError: true,
					data: executingEntityTypeReducerInitialState?.postExecutingEntityType?.data,
					error
				}
			};
		}
		// END POST

		// END Find plants filtered action handlers
		default: {
			return state;
		}
	}
};

export default executingEntityTypeReducer;

export const getExecutingEntityTypeReducer = state => state.executingEntityTypeReducer;

// Getters list executing entity type
export const getListExecutingEntityType = state => getExecutingEntityTypeReducer(state)?.listExecutingEntityType;
export const getListExecutingEntityTypeData = state => getListExecutingEntityType(state)?.data;
export const getListExecutingEntityTypeMetaData = state => getListExecutingEntityType(state)?.meta;
export const getListExecutingEntityTypeIsFetching = state => getListExecutingEntityType(state)?.isFetching;
export const getListExecutingEntityTypeHasError = state => getListExecutingEntityType(state)?.hasError;
export const getListExecutingEntityTypeError = state => getListExecutingEntityType(state)?.error;


// Getters post executing entity type
export const getPostExecutingEntityType = state => getExecutingEntityTypeReducer(state)?.postExecutingEntityType;
export const getPostExecutingEntityTypeData = state => getPostExecutingEntityType(state)?.data;
export const getPostExecutingEntityTypeIsFetching = state => getPostExecutingEntityType(state)?.isFetching;
export const getPostExecutingEntityTypeHasError = state => getPostExecutingEntityType(state)?.hasError;
export const getPostExecutingEntityTypesError = state => getPostExecutingEntityType(state)?.error;
