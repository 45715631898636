export const LOCALITY_REQUEST = 'LOCALITY_REQUEST';
export const LOCALITY_REQUEST_SUCCESS = 'LOCALITY_REQUEST_SUCCESS';
export const LOCALITY_REQUEST_FAILURE = 'LOCALITY_REQUEST_FAILURE';
export const CLEAR_LOCALITY_REQUEST = 'CLEAR_LOCALITY_REQUEST'; 

//post
export const POST_LOCALITY_REQUEST = 'POST_LOCALITY_REQUEST';
export const POST_LOCALITY_REQUEST_SUCCESS = 'POST_LOCALITY_REQUEST_SUCCESS';
export const POST_LOCALITY_REQUEST_FAILURE = 'POST_LOCALITY_REQUEST_FAILURE';

//put
export const PUT_LOCALITY_REQUEST = 'PUT_LOCALITY_REQUEST';
export const PUT_LOCALITY_REQUEST_SUCCESS = 'PUT_LOCALITY_REQUEST_SUCCESS';
export const PUT_LOCALITY_REQUEST_FAILURE = 'PUT_LOCALITY_REQUEST_FAILURE';

//delete
export const DELETE_LOCALITY_REQUEST = 'DELETE_LOCALITY_REQUEST';
export const DELETE_LOCALITY_REQUEST_SUCCESS = 'DELETE_LOCALITY_REQUEST_SUCCESS';
export const DELETE_LOCALITY_REQUEST_FAILURE = 'DELETE_LOCALITY_REQUEST_FAILURE';