export const CANCELLATION_REASON_REQUEST = 'CANCELLATION_REASON_REQUEST';
export const CANCELLATION_REASON_REQUEST_SUCCESS = 'CANCELLATION_REASON_REQUEST_SUCCESS';
export const CANCELLATION_REASON_REQUEST_FAILURE = 'CANCELLATION_REASON_REQUEST_FAILURE';
export const CLEAR_CANCELLATION_REASON_REQUEST = 'CLEAR_CANCELLATION_REASON_REQUEST'; 

//post
export const POST_CANCELLATION_REASON_REQUEST = 'POST_CANCELLATION_REASON_REQUEST';
export const POST_CANCELLATION_REASON_REQUEST_SUCCESS = 'POST_CANCELLATION_REASON_REQUEST_SUCCESS';
export const POST_CANCELLATION_REASON_REQUEST_FAILURE = 'POST_CANCELLATION_REASON_REQUEST_FAILURE';

//put
export const PUT_CANCELLATION_REASON_REQUEST = 'PUT_CANCELLATION_REASON_REQUEST';
export const PUT_CANCELLATION_REASON_REQUEST_SUCCESS = 'PUT_CANCELLATION_REASON_REQUEST_SUCCESS';
export const PUT_CANCELLATION_REASON_REQUEST_FAILURE = 'PUT_CANCELLATION_REASON_REQUEST_FAILURE';

//delete
export const DELETE_CANCELLATION_REASON_REQUEST = 'DELETE_CANCELLATION_REASON_REQUEST';
export const DELETE_CANCELLATION_REASON_REQUEST_SUCCESS = 'DELETE_CANCELLATION_REASON_REQUEST_SUCCESS';
export const DELETE_CANCELLATION_REASON_REQUEST_FAILURE = 'DELETE_CANCELLATION_REASON_REQUEST_FAILURE';
