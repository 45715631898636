import * as ACTION_TYPES from './groupActionTypes';

export const groupReducerInitialState = {
	listGroup: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postGroup: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const groupReducer = (state = groupReducerInitialState, action) => {
	switch (action.type) {

		// List filtered action handlers
		case ACTION_TYPES.GROUP_REQUEST: {

			return {
				...state,
				listGroup: {
					...state.listGroup,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.GROUP_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listGroup: {
					...state.listGroup,

					isFetching: false,
					hasError: false,
					error: groupReducerInitialState?.listGroup?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.GROUP_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listGroup: {
					...state.listGroup,
					isFetching: false,
					hasError: true,
					data: groupReducerInitialState?.listGroup?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_GROUP_REQUEST: {
			return {
				...state,
				listGroup: groupReducerInitialState?.listGroup
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_GROUP_REQUEST: {

			return {
				...state,
				postGroup: {
					...state.postGroup,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_GROUP_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postGroup: {
					...state.postGroup,

					isFetching: false,
					hasError: false,
					error: groupReducerInitialState?.postGroup?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_GROUP_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postGroup: {
					...state.postGroup,
					isFetching: false,
					hasError: true,
					data: groupReducerInitialState?.postGroup?.data,
					error
				}
			};
		}
		// END POST

		// END action handlers
		default: {
			return state;
		}
	}
};

export default groupReducer;

export const getGroupReducer = state => state.groupReducer;

// Getters list
export const getListGroup = state => getGroupReducer(state)?.listGroup;
export const getListGroupData = state => getListGroup(state)?.data;
export const getListGroupMetaData = state => getListGroup(state)?.meta;
export const getListGroupIsFetching = state => getListGroup(state)?.isFetching;
export const getListGroupHasError = state => getListGroup(state)?.hasError;
export const getListGroupError = state => getListGroup(state)?.error;


// Getters post
export const getPostGroup = state => getGroupReducer(state)?.postGroup;
export const getPostGroupData = state => getPostGroup(state)?.data;
export const getPostGroupIsFetching = state => getPostGroup(state)?.isFetching;
export const getPostGroupHasError = state => getPostGroup(state)?.hasError;
export const getPostGroupsError = state => getPostGroup(state)?.error;
