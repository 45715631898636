export const TYPIFICATION_REQUEST = 'TYPIFICATION_REQUEST';
export const TYPIFICATION_REQUEST_SUCCESS = 'TYPIFICATION_REQUEST_SUCCESS';
export const TYPIFICATION_REQUEST_FAILURE = 'TYPIFICATION_REQUEST_FAILURE';
export const CLEAR_TYPIFICATION_REQUEST = 'CLEAR_TYPIFICATION_REQUEST'; 

//post
export const POST_TYPIFICATION_REQUEST = 'POST_TYPIFICATION_REQUEST';
export const POST_TYPIFICATION_REQUEST_SUCCESS = 'POST_TYPIFICATION_REQUEST_SUCCESS';
export const POST_TYPIFICATION_REQUEST_FAILURE = 'POST_TYPIFICATION_REQUEST_FAILURE';

//put
export const PUT_TYPIFICATION_REQUEST = 'PUT_TYPIFICATION_REQUEST';
export const PUT_TYPIFICATION_REQUEST_SUCCESS = 'PUT_TYPIFICATION_REQUEST_SUCCESS';
export const PUT_TYPIFICATION_REQUEST_FAILURE = 'PUT_TYPIFICATION_REQUEST_FAILURE';

//delete
export const DELETE_TYPIFICATION_REQUEST = 'DELETE_TYPIFICATION_REQUEST';
export const DELETE_TYPIFICATION_REQUEST_SUCCESS = 'DELETE_TYPIFICATION_REQUEST_SUCCESS';
export const DELETE_TYPIFICATION_REQUEST_FAILURE = 'DELETE_TYPIFICATION_REQUEST_FAILURE';
