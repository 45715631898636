import * as ACTION_TYPES from './callActionTypes';

export const callReducerInitialState = {
	listCall: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postCall: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const callReducer = (state = callReducerInitialState, action) => {
	switch (action.type) {

		// List filtered action handlers
		case ACTION_TYPES.CALL_REQUEST: {

			return {
				...state,
				listCall: {
					...state.listCall,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.CALL_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listCall: {
					...state.listCall,

					isFetching: false,
					hasError: false,
					error: callReducerInitialState?.listCall?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.CALL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listCall: {
					...state.listCall,
					isFetching: false,
					hasError: true,
					data: callReducerInitialState?.listCall?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_CALL_REQUEST: {
			return {
				...state,
				listCall: callReducerInitialState?.listCall
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_CALL_REQUEST: {

			return {
				...state,
				postCall: {
					...state.postCall,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_CALL_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postCall: {
					...state.postCall,

					isFetching: false,
					hasError: false,
					error: callReducerInitialState?.postCall?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_CALL_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postCall: {
					...state.postCall,
					isFetching: false,
					hasError: true,
					data: callReducerInitialState?.postCall?.data,
					error
				}
			};
		}
		// END POST

		// END action handlers
		default: {
			return state;
		}
	}
};

export default callReducer;

export const getCallReducer = state => state.callReducer;

// Getters list
export const getListCall = state => getCallReducer(state)?.listCall;
export const getListCallData = state => getListCall(state)?.data;
export const getListCallMetaData = state => getListCall(state)?.meta;
export const getListCallIsFetching = state => getListCall(state)?.isFetching;
export const getListCallHasError = state => getListCall(state)?.hasError;
export const getListCallError = state => getListCall(state)?.error;


// Getters post
export const getPostCall = state => getCallReducer(state)?.postCall;
export const getPostCallData = state => getPostCall(state)?.data;
export const getPostCallIsFetching = state => getPostCall(state)?.isFetching;
export const getPostCallHasError = state => getPostCall(state)?.hasError;
export const getPostCallsError = state => getPostCall(state)?.error;
