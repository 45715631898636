import * as ACTION_TYPES from './typificationActionTypes';

export const typificationReducerInitialState = {
	listTypification: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postTypification: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const typificationReducer = (state = typificationReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.TYPIFICATION_REQUEST: {

			return {
				...state,
				listTypification: {
					...state.listTypification,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.TYPIFICATION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listTypification: {
					...state.listTypification,

					isFetching: false,
					hasError: false,
					error: typificationReducerInitialState?.listTypification?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.TYPIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listTypification: {
					...state.listTypification,
					isFetching: false,
					hasError: true,
					data: typificationReducerInitialState?.listTypification?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_TYPIFICATION_REQUEST: {
			return {
				...state,
				listTypification: typificationReducerInitialState?.listTypification
			};
		}
		// END executing entity type filtered action handlers

		// post executing entity type
		case ACTION_TYPES.POST_TYPIFICATION_REQUEST: {

			return {
				...state,
				postTypification: {
					...state.postTypification,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_TYPIFICATION_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postTypification: {
					...state.postTypification,

					isFetching: false,
					hasError: false,
					error: typificationReducerInitialState?.postTypification?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_TYPIFICATION_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postTypification: {
					...state.postTypification,
					isFetching: false,
					hasError: true,
					data: typificationReducerInitialState?.postTypification?.data,
					error
				}
			};
		}
		// END POST

		// END Find plants filtered action handlers
		default: {
			return state;
		}
	}
};

export default typificationReducer;

export const getTypificationReducer = state => state.typificationReducer;

// Getters list executing entity type
export const getListTypification = state => getTypificationReducer(state)?.listTypification;
export const getListTypificationData = state => getListTypification(state)?.data;
export const getListTypificationMetaData = state => getListTypification(state)?.meta;
export const getListTypificationIsFetching = state => getListTypification(state)?.isFetching;
export const getListTypificationHasError = state => getListTypification(state)?.hasError;
export const getListTypificationError = state => getListTypification(state)?.error;


// Getters post executing entity type
export const getPostTypification = state => getTypificationReducer(state)?.postTypification;
export const getPostTypificationData = state => getPostTypification(state)?.data;
export const getPostTypificationIsFetching = state => getPostTypification(state)?.isFetching;
export const getPostTypificationHasError = state => getPostTypification(state)?.hasError;
export const getPostTypificationsError = state => getPostTypification(state)?.error;
