import * as ACTION_TYPES from './loginActionTypes';

export const loginReducerInitialState = {
	hasError: false,
	isFetching: false,
	loginParams: undefined,
	isLoggedIn: false,
	authentication: undefined,
	error: undefined,
	permissions: undefined,
	redirect: { name: "Usuario", path: "/#/usuarios" }
};

export const loginReducer = (state = loginReducerInitialState, action) => {
	switch (action.type) {

		// Login actions
		case ACTION_TYPES.LOGIN_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
				loginParams: params
			};
		}
		case ACTION_TYPES.LOGIN_REQUEST_SUCCESS: {
			const isLoggedIn = (action && action.payload && !action.payload.hasOwnProperty("error"));
			return {
				...state,
				isFetching: false,
				hasError: false,
				error: undefined,
				password: undefined,
				isLoggedIn,
				authentication: action.payload,
			};
		}
		case ACTION_TYPES.LOGIN_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				isFetching: false,
				hasError: true,
				isLoggedIn: false,
				error
			};
		}
		case ACTION_TYPES.CLEAR_LOGIN_DATA: {
			return {
				...loginReducerInitialState
			};
		}
		case ACTION_TYPES.CLEAR_LOGIN_PARAMS: {
			return {
				...state,
				loginParams: loginReducerInitialState?.loginParams
			};
		}
		// END Login actions
    case ACTION_TYPES.LOGOUT_REQUEST: {
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.LOGOUT: {
			return {
				...loginReducerInitialState
			};
		}



		default: {
			return state;
		}
	}
};

export default loginReducer;

export const getLoginReducer = state => state.loginReducer;
export const getIsLoggedIn = state => getLoginReducer(state).isLoggedIn;

export const getIsFetching = state => getLoginReducer(state).isFetching;

export const getLoginParams = state => getLoginReducer(state).loginParams;

export const getUserAuthentication = state => getLoginReducer(state);
export const getUserLoggedData = state => getLoginReducer(state)?.authentication?.user;

export const getExpiresDate = state => getUserAuthentication(state)?.expires_dates;

export const getAccessToken = state => getUserAuthentication(state)?.authentication?.token?.access_token;
export const getProfileName = state => getUserAuthentication(state)?.profile_name;
export const getAlias = state => getUserAuthentication(state)?.alias;
export const getEmail = state => getUserAuthentication(state)?.email;
export const getPlantId = state => getUserAuthentication(state)?.plant_id;


