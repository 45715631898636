import * as ACTION_TYPES from './rolesActionTypes';

export const rolesReducerInitialState = {
	listRoles: {
		data: [],
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const rolesReducer = (state = rolesReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.ROLES_REQUEST: {

			return {
				...state,
				listRoles: {
					...state.listRoles,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.ROLES_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listRoles: {
					...state.listRoles,
					isFetching: false,
					hasError: false,
					error: rolesReducerInitialState?.listRoles?.error,
					data
				}
			};
		}
		case ACTION_TYPES.ROLES_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listRoles: {
					...state.listRoles,
					isFetching: false,
					hasError: true,
					data: rolesReducerInitialState?.listRoles?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_ROLES_REQUEST: {
			return {
				...state,
				listRoles: rolesReducerInitialState?.listRoles
			};
		}
		// END List plants filtered action handlers


		// END Find plants filtered action handlers
		default: {
			return state;
		}
	}
};

export default rolesReducer;

export const getRolesReducer = state => state.rolesReducer;

// Getters list candidates filtered
export const getListRoles = state => getRolesReducer(state)?.listRoles;
export const getListRolesData = state => getListRoles(state)?.data;
export const getListRolesIsFetching = state => getListRoles(state)?.isFetching;
export const getListRolesHasError = state => getListRoles(state)?.hasError;
export const getListRolesError = state => getListRoles(state)?.error;
