export const REPORT_TOTALITY_OF_INCIDENTS_REQUEST = 'REPORT_TOTALITY_OF_INCIDENTS_REQUEST';
export const REPORT_TOTALITY_OF_INCIDENTS_REQUEST_SUCCESS = 'REPORT_TOTALITY_OF_INCIDENTS_REQUEST_SUCCESS';
export const REPORT_TOTALITY_OF_INCIDENTS_REQUEST_FAILURE = 'REPORT_TOTALITY_OF_INCIDENTS_REQUEST_FAILURE';
export const CLEAR_REPORT_TOTALITY_OF_INCIDENTS_REQUEST = 'CLEAR_REPORT_TOTALITY_OF_INCIDENTS_REQUEST'; 

export const REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST = 'REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST';
export const REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_SUCCESS = 'REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_SUCCESS';
export const REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_FAILURE = 'REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST_FAILURE';
export const CLEAR_REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST = 'CLEAR_REPORT_TOTALITY_OF_INCIDENTS_NEW_REQUEST'; 

export const REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST = 'REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST';
export const REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_SUCCESS = 'REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_SUCCESS';
export const REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_FAILURE = 'REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST_FAILURE';
export const CLEAR_REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST = 'CLEAR_REPORT_TOTALITY_OF_INCIDENTS_IN_PROCESS_REQUEST'; 

export const REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST = 'REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST';
export const REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_SUCCESS = 'REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_SUCCESS';
export const REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_FAILURE = 'REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST_FAILURE';
export const CLEAR_REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST = 'CLEAR_REPORT_TOTALITY_OF_CALLS_RECEIVED_BY_OPERATORS_REQUEST'; 