import * as ACTION_TYPES from './cancellationReasonActionTypes';

export const cancellationReasonReducerInitialState = {
	listCancellationReason: {
		data: [],
		meta: null,
		isFetching: false,
		hasError: false,
		error: undefined
	},
	postCancellationReason: {
		data: undefined,
		isFetching: false,
		hasError: false,
		error: undefined
	},

};

export const cancellationReasonReducer = (state = cancellationReasonReducerInitialState, action) => {
	switch (action.type) {

		// List plants filtered action handlers
		case ACTION_TYPES.CANCELLATION_REASON_REQUEST: {

			return {
				...state,
				listCancellationReason: {
					...state.listCancellationReason,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.CANCELLATION_REASON_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				listCancellationReason: {
					...state.listCancellationReason,

					isFetching: false,
					hasError: false,
					error: cancellationReasonReducerInitialState?.listCancellationReason?.error,
					data: data?.data,
					meta: data?.meta
				}
			};
		}
		case ACTION_TYPES.CANCELLATION_REASON_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listCancellationReason: {
					...state.listCancellationReason,
					isFetching: false,
					hasError: true,
					data: cancellationReasonReducerInitialState?.listCancellationReason?.data,
					error
				}
			};
		}
		case ACTION_TYPES.CLEAR_CANCELLATION_REASON_REQUEST: {
			return {
				...state,
				listCancellationReason: cancellationReasonReducerInitialState?.listCancellationReason
			};
		}
		// END filtered action handlers

		// post
		case ACTION_TYPES.POST_CANCELLATION_REASON_REQUEST: {

			return {
				...state,
				postCancellationReason: {
					...state.postCancellationReason,
					isFetching: true,
					hasError: false
				}
			};
		}
		case ACTION_TYPES.POST_CANCELLATION_REASON_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postCancellationReason: {
					...state.postCancellationReason,

					isFetching: false,
					hasError: false,
					error: cancellationReasonReducerInitialState?.postCancellationReason?.error,
					data: data?.data
				}
			};
		}
		case ACTION_TYPES.POST_CANCELLATION_REASON_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postCancellationReason: {
					...state.postCancellationReason,
					isFetching: false,
					hasError: true,
					data: cancellationReasonReducerInitialState?.postCancellationReason?.data,
					error
				}
			};
		}
		// END POST

		// END Find plants filtered action handlers
		default: {
			return state;
		}
	}
};

export default cancellationReasonReducer;

export const getCancellationReasonReducer = state => state.cancellationReasonReducer;

// Getters list
export const getListCancellationReason = state => getCancellationReasonReducer(state)?.listCancellationReason;
export const getListCancellationReasonData = state => getListCancellationReason(state)?.data;
export const getListCancellationReasonMetaData = state => getListCancellationReason(state)?.meta;
export const getListCancellationReasonIsFetching = state => getListCancellationReason(state)?.isFetching;
export const getListCancellationReasonHasError = state => getListCancellationReason(state)?.hasError;
export const getListCancellationReasonError = state => getListCancellationReason(state)?.error;


// Getters post
export const getPostCancellationReason = state => getCancellationReasonReducer(state)?.postCancellationReason;
export const getPostCancellationReasonData = state => getPostCancellationReason(state)?.data;
export const getPostCancellationReasonIsFetching = state => getPostCancellationReason(state)?.isFetching;
export const getPostCancellationReasonHasError = state => getPostCancellationReason(state)?.hasError;
export const getPostCancellationReasonsError = state => getPostCancellationReason(state)?.error;
